.about {
    margin: auto;
    color: #fff;
    background: #1c1c1c;
    padding: 10px;
    font-size: larger;
    height: 100%;
    overflow: auto;
}

.inner {
    background: linear-gradient(145deg, hsl(200 100% 80%), hsl(100 100% 80%), hsl(100 100% 40%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 90%;
}

.hidden {
    opacity: 0;
}

.smiley {
    background: #1c1c1c;
    background-clip: initial;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
}

.be {
    /* display: inline-block; */
    display: flex;
    fill: turquoise;
    position: absolute;
    background: initial;
    background-clip: initial;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    transform: translateY(0em);
}

.beIn {
    fill: turquoise;
    opacity: 1;
    animation: dropIn 2s ease-out;
    animation-name: dropIn;
}

.beOut {
    opacity: 0;
    animation: dropOut 2s ease-out;
    animation-name: dropOut;
}

@keyframes dropIn {
    from {
        opacity: 0;
        transform: translateY(-1em);
    }
    to {
        opacity: 1;
        transform: translateY(0em);
    }
}

@keyframes dropOut {
    from {
        opacity: 1;
        transform: translateY(0em);
    }
    to {
        opacity: 0;
        transform: translateY(1em);
    }
}

.lll {
    font-family: cursive;
}

.lll:hover {
    font-weight: bold;
}
