.consent {
    position: fixed;
    bottom: 0px;
    width: 100%;
    font-size: 14px;
    background: #21354a;
    align-items: center;
    justify-content: center;
}

.consent span {
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.consent button {
    margin: 16px;
    padding: 6px;
    cursor: pointer;
}
