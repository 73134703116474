@font-face {
  font-family: "FOneBold";
  src: local("FOneBold"), url(./fonts/fone-bold.otf) format("opentype");
}
@font-face {
  font-family: "FOneRegular";
  src: local("FOneRegular"), url(./fonts/fone-regular.otf) format("opentype");
}
@font-face {
  font-family: "FOneWide";
  src: local("FOneWide"), url(./fonts/fone-wide.otf) format("opentype");
}

body {
  height: 100%;
  position: fixed;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* prevent overscroll bounce*/
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
