* {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.nav-glass {
    position: absolute;
    top: 48px;
    left: 0;
    background: #000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s linear;
}

.nav-glass-open {
    opacity: 0.5;
    pointer-events: auto;
}

.burger {
    position: absolute;
    top: 8px;
    left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    pointer-events: auto;
    outline-style: none;
}

.burger:focus {
    outline: none;
}

.burger div {
    width: 2rem;
    height: 0.25rem;
    background: #fff;
    border-radius: 10px;
    transition: all 0.2s linear;
    position: relative;
    transform-origin: 1px;
    transform: rotate(0);
}

.burger div.burger-1-open {
    transform: rotate(45deg);
}

.burger div.burger-2-open {
    opacity: 0;
    transform: translateX(-40px);
}

.burger div.burger-3-open {
    transform: rotate(-45deg);
}

nav {
    position: absolute;
    top: 48px;
    background: #111;
    padding: 20px;
    border-bottom: 4px solid #f22;
    border-right: 4px solid #f22;
    transition: all 0.2s linear;
}

.nav-closed {
    transform: translateX(-110%);
}

nav div {
    padding: 10px;
    outline-style: none;
}

nav a {
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.2s linear;
    outline-style: none;
}

nav a:hover {
    color: #f22;
}
