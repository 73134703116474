.title {
  text-align: center;
  line-height: 48px;
  font-size: 24px;
  font-weight: bold;
  /* padding-left: 60px; */
  color: #fff;
  background: #111;
}

.title a {
  text-decoration: inherit;
  color: inherit;
}

svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
svg text::selection {
  background: none;
}

h1 {
  background: #511;
  padding: 8px;
  padding-left: 20px;
  font-size: large;
}

h2 {
  background: #135;
  padding: 6px;
  padding-left: 20px;
  font-size: medium;
}

p {
  padding-left: 20px;
  padding-right: 20px;
}
